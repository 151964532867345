import { GoogleOAuthProvider } from "@react-oauth/google";
import SmartBanner from "components/_Shared/SmartBanner";
import { ChatbotProvider } from "context/ChatbotContext";
import { ExpiredProvider } from "context/ExpiredProvider/ExpiredProvider";
import { ModalVideoProvider } from "context/ModalVideoProvider/ModalVideoContext";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { MaterialCardsProvider } from "./context/MaterialCardsContext";
import { ModalBannerProvider } from "./context/ModalBannerContext";
import { NavbarStorage } from "./context/NavbarContext";
import { SlideStorage } from "./context/SlideContext";
import { ToastStorage } from "./context/ToastContext";
import { UserStorage } from "./context/UserContext";
import Routes from "./routes/main.routes";

import "react-phone-number-input/style.css";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const App = () => {
  return (
    <BrowserRouter>
      <GoogleOAuthProvider clientId="678774093994-pm0mpg0rrfvh93c4p06shvd5mvam5msl.apps.googleusercontent.com">
        <UserStorage>
          <ChatbotProvider>
            <ModalBannerProvider>
              <ModalVideoProvider>
                <ExpiredProvider>
                  <MaterialCardsProvider>
                    <ToastStorage>
                      <SlideStorage>
                        <NavbarStorage>
                          <SmartBanner />
                          <ToastContainer />
                          <Routes />
                        </NavbarStorage>
                      </SlideStorage>
                    </ToastStorage>
                  </MaterialCardsProvider>
                </ExpiredProvider>
              </ModalVideoProvider>
            </ModalBannerProvider>
          </ChatbotProvider>
        </UserStorage>
      </GoogleOAuthProvider>
    </BrowserRouter>
  );
};

export default App;
