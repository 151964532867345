/* eslint-disable jsx-a11y/no-onchange */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import Pagination from "../../../_Shared/Pagination/Pagination";
import { SpinnerPurple, Configuration } from "../../../../assets/svgs/index";
import ButtonSvg from "../../../../components/_Shared/ButtonSvg/ButtonSvg";
import useRequest from "../../../../hooks/useRequest";
import months from "../../../../utils/months";
import { parseFilterQuery, monthNumberToName } from "../../../../utils/utils";
import Class from "./Class";
import DropdownFilters from "./DropdownFilters";

const Classes = () => {
  const { request } = useRequest();
  const { t } = useTranslation();
  const { search } = useLocation();

  const [key, setKey] = useState(null);
  const [lessons, setLessons] = useState({});
  const [paginate, setPaginate] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [showClassTypeFilter, setShowClassTypeFiler] = useState(false);
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [currentFilters, setCurrentFilters] = useState(
    JSON.parse(sessionStorage.getItem("teacherClassesFilter")) ?? [],
  );

  const currentMonth = new Date().getMonth() + 1;

  // Status que consideramos a aula teste como convertida
  const soldStatus = [
    "[AP] - Inadimplente",
    "[AP] - Compra Aprovada",
    "[AP] - Plano Cancelado",
    "[OB] - Agendar 1a aula",
    "[OB] - 1a aula agendada",
    "[OB] - Aulas pendentes",
    "[OB] - Todas Aulas Agendadas",
    "[OB] - Acompanhamento",
    "[OB] - Retido",
    "[OB] - Utilizando",
    "[OB] - Não utilizando",
    "[OB] - Retido Live",
  ];

  const fetchLessons = async (page = 0) => {
    setIsLoading(true);

    let filter;

    switch (key) {
      case 1:
        filter = "RESERVED";
        break;
      case 2:
        filter = "PENDING";
        break;
      case 3:
        filter = "COMPLETED";
        break;
      default:
        console.error("Unknown filter");
        break;
    }

    const filterQuery = parseFilterQuery(currentFilters, "&");
    const response = await request(
      "GET",
      `/appointment/teacher/list?filter=${filter}&page=${page + 1}&perPage=5&month=${month}&year=${year}${filterQuery}`,
    );

    setPaginate({ totalPages: response.totalPages, totalResults: response.totalResults });
    setLessons(response);
    setIsLoading(false);
  };

  const refreshTime = 180000; // 3 minutos
  useEffect(() => {
    key && fetchLessons();

    const intervalId = setInterval(fetchLessons, refreshTime);
    return () => clearInterval(intervalId);
  }, [key]);

  useEffect(() => {
    const filter = new URLSearchParams(search).get("filter");
    if (filter) {
      setKey(3);
    } else {
      setKey(1);
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem("teacherClassesFilter", JSON.stringify(currentFilters));
  }, [currentFilters]);

  useEffect(() => {
    key && fetchLessons();
  }, [month, year, currentFilters]);

  const handlePageChange = ({ selected }) => {
    fetchLessons(selected);
    setPageNumber(selected);
  };

  return (
    <>
      <div className="myEvents-bg shadow-md">
        <div className="grid grid-cols-1 2xl:grid-cols-4 lg:grid-cols-5 px-4">
          <div
            className="py-4"
            style={{
              color: "#252426",
            }}>
            <p className="text-sm">Início / Aulas</p>
            <h1 className="text-3xl font-medium mt-5">Aulas</h1>
          </div>
        </div>
        <div className="grid grid-cols-1 2xl:grid-cols-3">
          <div className="2xl:col-span-2">
            <div
              className="mx-5 p-4 mt-5 bg-white rounded-2xl shadow-md text-gray-600 grid grid-cols-1 md:grid-cols-4 relative"
              style={{
                height: "4.875rem",
              }}>
              <div className="grid grid-cols-4 col-span-3 text-center font-gray text-sm">
                <button onClick={() => setKey(1)} className="relative">
                  <p className={`${key === 1 && "font-aqua-green font-semibold"} "p-4"`}>
                    {t("class.reserved_classes")}
                  </p>
                  {key === 1 && (
                    <div style={{ top: "60px" }} className="absolute border-t-4 border-aqua-green w-full rounded" />
                  )}
                </button>
                <button onClick={() => setKey(2)} className="relative">
                  <p className={`${key === 2 && "font-aqua-green font-semibold"} "p-4"`}>
                    {t("class.pending_classes")}
                  </p>
                  {key === 2 && (
                    <div style={{ top: "60px" }} className="absolute border-t-4 border-aqua-green w-full rounded" />
                  )}
                </button>

                <button onClick={() => setKey(3)} className="relative">
                  <p className={`${key === 3 && "font-aqua-green font-semibold"} "p-4"`}>
                    {t("class.completed_classes")}
                  </p>
                  {key === 3 && (
                    <div style={{ top: "60px" }} className="absolute border-t-4 border-aqua-green w-full rounded" />
                  )}
                </button>
              </div>
              <div className="flex justify-end gap-2 md:col-start-4 col-span-2">
                <div className="relative mt-3">
                  <select
                    className="cursor-pointer bg-transparent"
                    onChange={({ target }) => {
                      setMonth(target.value);
                    }}>
                    <option selected disabled value="">
                      Month
                    </option>
                    {months.map(({ label, value }, index) => (
                      <option selected={value === currentMonth} key={index} value={value}>
                        {label}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="relative mt-3">
                  <select
                    className="cursor-pointer bg-transparent"
                    onChange={({ target }) => {
                      setYear(target.value);
                    }}>
                    <option disabled value="">
                      Year
                    </option>
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option selected value="2025">
                      2025
                    </option>
                  </select>
                </div>

                <ButtonSvg
                  clickHandler={() => setShowClassTypeFiler(true)}
                  className={`p-3 bg-white border border-gray-300 max-h-12  ${
                    currentFilters?.length && "filter-active"
                  }`}>
                  <Configuration className="w-5" />
                </ButtonSvg>
              </div>

              {showClassTypeFilter && (
                <DropdownFilters
                  setCurrentFilters={setCurrentFilters}
                  currentFilters={currentFilters}
                  setIsOpen={setShowClassTypeFiler}
                />
              )}
            </div>

            {isLoading ? (
              <SpinnerPurple className="w-10 m-auto mt-5 animate-spin" />
            ) : (
              <div className="mx-5 mt-8">
                <h1
                  style={{
                    color: "#252426",
                    fontSize: "1.625rem",
                  }}>
                  {t("you_have")}
                </h1>

                {key === 1 && (
                  <h2
                    className="text-base"
                    style={{
                      color: "#625E66",
                    }}>
                    {lessons.totalResults} {t("class.scheduled_classes")}
                  </h2>
                )}

                {key === 2 && (
                  <h2
                    className="text-base"
                    style={{
                      color: "#625E66",
                    }}>
                    {lessons.totalResults} classes to confirm
                  </h2>
                )}

                {key === 3 && (
                  <div>
                    <h2
                      className="text-base"
                      style={{
                        color: "#625E66",
                      }}>
                      {lessons.totalResults} {`${lessons.totalResults > 0 ? "Classes" : "Class"}`} done classes in{" "}
                      {monthNumberToName(month)}
                    </h2>
                  </div>
                )}

                <div className="mt-8">
                  {lessons?.results?.map((lesson, index) => {
                    const isSoldTestClass = key === 3 && lesson.isTestClass && soldStatus.includes(lesson.user.status);

                    return (
                      <Class
                        key={lesson.id}
                        uuid={lesson.uuid}
                        uuidStudent={lesson.user.uuid}
                        cottar={key}
                        setCottar={setKey}
                        imageUrl={lesson.user.profilePictureUrl}
                        name={lesson.user.name}
                        studentNote={lesson.studentNote}
                        teacherNote={lesson.teacherNote}
                        datetime={lesson.schedule.dateTime}
                        minutesLesson={lesson.type}
                        refresh={fetchLessons}
                        isLastRecord={lessons?.results.length - 1 === index}
                        isTestClass={lesson.isTestClass}
                        isSoldTestClass={isSoldTestClass}
                        teacherMeetingUrl={lesson.teacherMeetingUrl}
                      />
                    );
                  })}
                </div>
              </div>
            )}

            {!isLoading && paginate.totalPages > 1 && (
              <div className="mt-4 mb-4">
                <Pagination
                  forcePage={pageNumber}
                  totalPages={paginate.totalPages}
                  handlePageChange={handlePageChange}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Classes;
