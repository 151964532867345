/* eslint-disable prettier/prettier */
import PropTypes from "prop-types";
import React, { createContext, useState, useRef, useEffect, useContext } from "react";
import YouTube from "react-youtube";
import { logAnalyticsEvent } from "utils/firebase-analytics";

import * as S from "./styles.module.css";

const StateContext = createContext();
const SKIP_TIME = 30; // Tempo em segundos para mostrar o botão

const banners = {
  testClass: {
    title: "Assinatura Confirmada!",
    subTitle: "Assista o vídeo até o final e aprenda a organizar sua rotina de estudos.",
    cta: null,
    videoId: "YxE0RBzkVjQ",
    analyticsEvent: "MODAL_VIDEO_PURCHASE",
  },
};

export const ModalVideoProvider = ({ children }) => {
  const [progress, setProgress] = useState(0);
  const [showSkipButton, setShowSkipButton] = useState(false);
  const [showModalVideo, setShowModalVideo] = useState(false);

  const intervalRef = useRef(null);
  const playerRef = useRef(null);
  const videoDurationRef = useRef(0);

  const banner = banners["testClass"];

  const opts = {
    playerVars: {
      autoplay: 1,
      controls: 0,
      disablekb: 1,
      rel: 0,
      fs: 0,
      iv_load_policy: 3,
    },
  };

  const handleReady = (event) => {
    playerRef.current = event.target;
    // videoDurationRef.current = playerRef.current.getDuration();
    videoDurationRef.current = SKIP_TIME;
  };

  const handleStateChange = (event) => {
    const state = event.data;

    if (state === YouTube.PlayerState.PLAYING) {
      intervalRef.current = setInterval(() => {
        if (playerRef.current) {
          const currentTime = playerRef.current.getCurrentTime();
          const duration = videoDurationRef.current;

          if (duration > 0) {
            const newProgress = (currentTime / duration) * 100;

            if (newProgress <= 100) {
              setProgress(newProgress);
            }

            setShowSkipButton(currentTime >= SKIP_TIME);
          }
        }
      }, 100);
    } else {
      clearInterval(intervalRef.current);
      intervalRef.current = null;

      if (state === YouTube.PlayerState.ENDED) {
        setProgress(100);
        setShowSkipButton(false);
      }
    }
  };

  const handleSkip = () => {
    setShowModalVideo(false);
    logAnalyticsEvent("SKIP_" + banner.analyticsEvent);
  };

  const handleCta = () => {
    setShowModalVideo(false);
    logAnalyticsEvent("CLICK_" + banner.analyticsEvent);
  };

  useEffect(() => {
    if(showModalVideo) {
      logAnalyticsEvent("SHOW_" + banner.analyticsEvent);
    }
  }, [showModalVideo]);

  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current);
      if (playerRef.current) {
        playerRef.current.destroy();
      }
    };
  }, []);

  return (
    <StateContext.Provider value={{ setShowModalVideo }}>
      {showModalVideo && (
        <div className={S.Backdrop}>
          <div className={S.Container}>
            <div className={S.Wrapper}>
              <div className={S.TitleWrapper}>
                <h2 dangerouslySetInnerHTML={{ __html: banner.title }} />
                <h3 style={{textAlign: "center"}}>{banner.subTitle}</h3>
              </div>

              <YouTube
                videoId={banner.videoId}
                opts={opts}
                onReady={handleReady}
                iframeClassName={S.YtIframe}
                onStateChange={handleStateChange}
              />

              <div className={S.progressContainer}>
                <div className={S.progressBar} style={{ width: `${progress}%` }} />
              </div>

              <div className={S.TextInfo}>Você poderá fechar o vídeo em breve</div>
              <div className={S.ButtonsWrapper}>
                {banner.cta && (<button className={S.CTAButton} onClick={handleCta}>
                  {banner.cta}
                </button>)}
                <button className={S.CTAButton} disabled={!showSkipButton} onClick={handleSkip}>
                  FECHAR
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {children}
    </StateContext.Provider>
  );
};
export const useModalVideo = () => useContext(StateContext);

ModalVideoProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
